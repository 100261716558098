import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import IconClose from "assets/images/icons/IconClose";
import Button from "components/common/Button";
import Loader from "components/common/Loader";
import Tooltip from "components/common/Tooltip";
import { useLazyGetProjectQuery } from "services/api/projects";
import { useAppDispatch, useAppSelector } from "stores";
import { brandingUpdated } from "stores/features/appBuilder/branding";
import { selectPrivateKey } from "stores/features/project";
import {
  selectIsFontello,
  selectShowDocs,
  selectShowDocsKey,
  uiUpdated,
} from "stores/features/ui";
import api from "utils/api";
import { handleError } from "utils/errors";
import urls from "utils/urls";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
}

const Documentation: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const [getProject] = useLazyGetProjectQuery();
  const privateKey = useAppSelector(selectPrivateKey);
  const showDocs = useAppSelector(selectShowDocs);
  const showDocsKey = useAppSelector(selectShowDocsKey);
  const isFontello = useAppSelector(selectIsFontello);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const ref = useRef<HTMLIFrameElement>(null);

  // Listen to fontello's export button click
  useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    if (isFontello) {
      interval = setInterval(async () => {
        let iframeUrl = "";
        try {
          iframeUrl = ref.current?.contentWindow?.location.href || "";
        } catch {
          // NO OP
        }

        try {
          if (iframeUrl.startsWith(urls.app)) {
            dispatch(uiUpdated({ showDocs: null }));

            const customIcons = await api.icons.getBrandingCustomIcons(
              privateKey
            );
            dispatch(brandingUpdated({ customIcons }));

            toast(
              t("containers.rightPanel.documentation.customIconsImported"),
              { type: "success" }
            );
          }
        } catch (e) {
          handleError(e, { t });
        }
      }, 200);
    }
    return () => clearInterval(interval);
  }, [dispatch, getProject, isFontello, privateKey, t]);

  useEffect(() => {
    if (showDocs) {
      setIsLoading(true);
    }
  }, [showDocs, showDocsKey]);

  return (
    <div
      className={classNames(
        styles.documentationContainer,
        isFontello && styles.fontello,
        className
      )}
    >
      <div className={styles.documentation}>
        <header className={styles.documentationHeader}>
          <h2 className={styles.documentationHeaderTitle}>
            {isFontello
              ? t("containers.rightPanel.documentation.customIcons")
              : t("containers.rightPanel.documentation.title")}

            {isLoading && (
              <Loader className={styles.documentationHeaderTitleLoader} />
            )}
          </h2>
          <Tooltip label={t("common.closeDocs")} placement="left">
            <Button
              onClick={() => dispatch(uiUpdated({ showDocs: null }))}
              allowOnPreviewMode
            >
              <IconClose className={styles.iconClose} />
            </Button>
          </Tooltip>
        </header>

        {showDocs && (
          <iframe
            ref={ref}
            key={showDocsKey}
            className={styles.documentationIframe}
            onLoad={() => setIsLoading(false)}
            src={showDocs}
            title={t("containers.rightPanel.documentation.title")}
          />
        )}

        {!showDocs && isFontello && (
          <div className={styles.documentationIframe} />
        )}
      </div>
    </div>
  );
};

export default Documentation;
