import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React from "react";

import IconChevronLeft from "assets/images/icons/chevronLeft.svg";
import IconChevronRight from "assets/images/icons/chevronRight.svg";
import IconClose from "assets/images/icons/IconClose";
import CostSummary from "components/containers/Pricing/CostSummary";
import { useAppDispatch, useAppSelector } from "stores";
import { selectIsRightPanelCollapsed, uiUpdated } from "stores/features/ui";
import { useMobile, usePerfectScrollbar } from "utils/hooks";

import styles from "./styles.module.scss";

const Cart: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMobile();

  const isRightPanelCollapsed = useAppSelector(selectIsRightPanelCollapsed);
  const { t } = useTranslation();
  const containerRef = usePerfectScrollbar<HTMLDivElement>();

  return (
    <div
      className={classNames(styles.cartContainer, {
        [styles.collapsed]: isRightPanelCollapsed,
      })}
      ref={containerRef}
    >
      <header
        className={classNames(
          styles.cartHeader,
          isRightPanelCollapsed && styles.collapsed
        )}
      >
        {!isRightPanelCollapsed && <div className={styles.spacer} />}
        {/* {!isRightPanelCollapsed && (
          <div className={styles.costSummaryHeader}>
            {t("containers.pricing.costSummary.title")}
          </div>
        )} */}

        <button
          className={classNames(
            styles.cartToggle,
            isRightPanelCollapsed && styles.collapsed
          )}
          onClick={() => {
            dispatch(
              uiUpdated({
                isRightPanelCollapsed: !isRightPanelCollapsed,
              })
            );
            if (isMobile) {
              dispatch(
                uiUpdated({
                  forceShowCart: false,
                })
              );
            }
          }}
          title={
            isRightPanelCollapsed ? t("common.expand") : t("common.collapse")
          }
          type="button"
        >
          <span className={styles.cartToggleContent}>
            {isRightPanelCollapsed && (
              <h2
                className={classNames(styles.cartHeaderTitle, styles.collapsed)}
              >
                {t("containers.rightPanel.cart.titleLicense")}
              </h2>
            )}
            <div className={styles.icon}>
              {isRightPanelCollapsed ? (
                <IconChevronLeft />
              ) : (
                <>
                  <IconChevronRight className="gn-hidden gn-block-desktop" />
                  <IconClose className="gn-block gn-hidden-desktop" />
                </>
              )}
            </div>
          </span>
        </button>
      </header>
      {!isRightPanelCollapsed && (
        <CostSummary className={styles.cartCostSummary} type="appBuilder" />
      )}
    </div>
  );
};

export default Cart;
