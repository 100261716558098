import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useLocalStorage, usePrevious } from "react-use";

import { useAppDispatch, useAppSelector } from "stores";
import {
  ActiveCart,
  selectCart,
  selectIsCartLoaded,
} from "stores/features/cart";
import { selectPrivateKey } from "stores/features/project";
import {
  selectIsFontello,
  selectIsRightPanelCollapsed,
  selectShowDocs,
  uiUpdated,
} from "stores/features/ui";
import { useDesktop, useIsLoaded, useMobile } from "utils/hooks";
import { storageKeys } from "utils/storage";

import Cart from "./Cart";
import Documentation from "./Documentation";
import Preview from "./Preview";
import styles from "./styles.module.scss";
import { useRightPanelType } from "./utils";

const RightPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const isFontello = useAppSelector(selectIsFontello);
  const cart = useAppSelector(selectCart);
  const isRightPanelCollapsed = useAppSelector(selectIsRightPanelCollapsed);
  const showDocs = useAppSelector(selectShowDocs);
  const rightPanelType = useRightPanelType();
  const isDesktop = useDesktop();
  const isMobile = useMobile();
  const isLoaded = useIsLoaded();
  const [initialItem, setInitialItem] = useState(false);
  const isCartLoaded = useAppSelector(selectIsCartLoaded);
  const prevCart = useRef(cart);
  const privateKey = useAppSelector(selectPrivateKey);

  const [activeCart, setActiveCart, clearActiveCart] =
    useLocalStorage<ActiveCart>(storageKeys.activeCart());

  const prevIsCartLoadedStatus = usePrevious(isCartLoaded);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === storageKeys.activeCart()) {
        if (event.newValue === null) {
          clearActiveCart();
        } else {
          const updatedCart = JSON.parse(event.newValue);
          setActiveCart(updatedCart);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [clearActiveCart, setActiveCart]);

  useEffect(() => {
    if (isCartLoaded) {
      const currentTime = new Date().toISOString();
      const updatedCart = {
        ...activeCart,
        [privateKey]: {
          cart: { ...cart },
          app: true,
          lastActive: currentTime,
        },
      };
      setActiveCart(updatedCart);
    } else if (prevIsCartLoadedStatus && !isCartLoaded) {
      const { [privateKey]: removedEntry, ...rest } = activeCart || {};
      setActiveCart(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, isCartLoaded, prevIsCartLoadedStatus, privateKey, setActiveCart]);

  useEffect(() => {
    if (isLoaded) {
      dispatch(
        uiUpdated({
          isRightPanelCollapsed: !isDesktop,
          isLeftNavCollapsed: isMobile,
        })
      );
    }
  }, [dispatch, isDesktop, isMobile, isLoaded]);

  useEffect(() => {
    if (
      prevCart.current !== cart &&
      !isMobile &&
      isRightPanelCollapsed &&
      isCartLoaded
    ) {
      dispatch(uiUpdated({ isRightPanelCollapsed: false }));
    }
    prevCart.current = cart;
  }, [
    cart,
    dispatch,
    isCartLoaded,
    isDesktop,
    isMobile,
    isRightPanelCollapsed,
  ]);

  useEffect(() => {
    if (isMobile && !initialItem && isCartLoaded) {
      dispatch(
        uiUpdated({ isRightPanelCollapsed: false, forceShowCart: true })
      );
      setInitialItem(true);
    }
    if (!isCartLoaded) {
      setInitialItem(false);
    }
  }, [dispatch, initialItem, isCartLoaded, isMobile]);

  return (
    <div
      className={classNames(
        styles.rightPanel,
        (showDocs || isFontello) && styles.showDocs
      )}
    >
      <Documentation
        className={classNames(
          !showDocs && !isFontello && styles.rightPanelHideItem
        )}
      />

      <Preview
        className={classNames(
          (showDocs || isFontello || rightPanelType !== "preview") &&
            styles.rightPanelHideItem
        )}
      />

      {!showDocs && !isFontello && rightPanelType === "cart" && <Cart />}
    </div>
  );
};

export default RightPanel;
