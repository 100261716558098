import classNames from "classnames";
import Head from "next/head";
import React, { useEffect, useRef } from "react";

import IconLeft from "assets/images/icons/IconLeft.svg";
import Button from "components/common/Button";
import LeftNav from "components/containers/LeftNav";
import Main from "components/containers/Main";
import RightPanel from "components/containers/RightPanel";
import TopBar from "components/containers/TopBar";
import { BackupItem } from "services/api/backups";
import { useListPluginsQuery } from "services/api/plugins";
import { useAppDispatch, useAppSelector } from "stores";
import { defaultOpenGraphImage } from "stores/constants";
import { selectAppIcon } from "stores/features/appBuilder/branding";
import {
  selectAppName,
  selectContact,
  selectOverview,
} from "stores/features/appBuilder/overview";
import { selectCartPrice } from "stores/features/cart";
import { selectIsShopifyApp, selectPrivateKey } from "stores/features/project";
import { uiUpdated } from "stores/features/ui";
import { selectIsAuthenticated, selectUser } from "stores/features/user";
import { trackEvent, trackLead } from "utils/analytics";
import { SECTION } from "utils/constants";
import { useAppSection, useImage } from "utils/hooks";

import IconPreview from "../TopBar/images/IconPreview";
import styles from "./styles.module.scss";

interface Props {
  backupItem?: BackupItem;
}

const AppBuilder: React.FC<Props> = ({ backupItem }) => {
  const appName = useAppSelector(selectAppName);
  const appIcon = useImage(useAppSelector(selectAppIcon));
  const defaultOgImage = useImage(defaultOpenGraphImage);
  const privateKey = useAppSelector(selectPrivateKey);
  const [appSection] = useAppSection();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const user = useAppSelector(selectUser);
  const overview = useAppSelector(selectOverview);

  const { data: nativePluginsList = [] } = useListPluginsQuery();
  const cartPrice = useAppSelector((state) =>
    selectCartPrice(state, nativePluginsList)
  );
  const prevCartPrice = useRef(cartPrice);

  useEffect(() => {
    if (prevCartPrice.current !== cartPrice) {
      trackLead({
        email: user.email,
        cartPrice,
      });
      prevCartPrice.current = cartPrice;
    }
  }, [cartPrice, user.email]);

  useEffect(() => {
    if (isAuthenticated) {
      const { firstName, lastName } = user;
      trackEvent("get_user", {
        email: user.email,
        firstName,
        lastName,
      });
      trackLead({
        email: user.email,
        firstname: firstName,
        lastname: lastName,
      });
    } else {
      trackEvent("get_user", {
        email: overview.email,
      });
    }
  }, [
    isAuthenticated,
    overview.email,
    user.firstName,
    user.lastName,
    user,
    user.email,
  ]);

  const isShopifyApp = useAppSelector(selectIsShopifyApp);
  const email = useAppSelector(selectContact);

  const readonly = appSection === SECTION.manage;

  const ogImage = appName ? appIcon : defaultOgImage;

  useEffect(() => {
    if (isShopifyApp && !email) {
      dispatch(uiUpdated({ authModalType: "inputEmailShopify" }));
    }
  }, [dispatch, email, isShopifyApp]);

  return (
    <>
      <Head>
        {ogImage && (
          <meta property="og:image" content={ogImage} key="og:image" />
        )}
        {appName && (
          <meta
            property="og:title"
            content={`${appName} - Median App Studio`}
            key="og:title"
          />
        )}

        {appName && (
          <meta
            property="og:description"
            content={`${appName} was developed using the Median App Studio. Median is used by industry leading web developers and corporate IT professionals to build iOS and Android apps that display web based content. Preview your app immediately using our browser-based simulators.`}
            key="og:description"
          />
        )}

        {appIcon && (
          <meta
            property="twitter:image"
            content={appIcon}
            key="twitter:image"
          />
        )}
        {appName && (
          <meta
            property="twitter:title"
            content={`${appName} - Median App Studio`}
            key="twitter:title"
          />
        )}
      </Head>
      <div className={styles.container}>
        <div
          className={classNames(styles.main, readonly && styles.dashboardMain)}
        >
          {!readonly && <TopBar readonly={readonly} backupItem={backupItem} />}
          {readonly && (
            <header>
              <Button
                className={styles.toggle}
                onClick={() =>
                  dispatch(uiUpdated({ isRightPanelCollapsed: false }))
                }
                allowOnPreviewMode
              >
                <IconLeft className={styles.toggleChevron} />

                <IconPreview className={styles.toggleIcon} width={20} />
              </Button>
            </header>
          )}

          <div className={styles.content}>
            {!readonly && <LeftNav disabled={!privateKey} />}

            <Main />
          </div>
        </div>

        <RightPanel />
      </div>
    </>
  );
};

export default AppBuilder;
